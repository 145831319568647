import React from "react";

export function DisplayedCut(props) {
  return (
    <g data-testid={`displayedCut-${props.name}`}>
      <rect
        x={props.x}
        width={props.width}
        height={props.wof}
        style={props.defaultStyle}
      />
      {props.subcutDisplay}
    </g>
  );
}

export function DisplayedSubcut(props) {
  let fontsize =
    Math.min(Math.min(props.width, props.height) / 2, 20).toString() + "px";
  return (
    <g
      data-testid={"displayedSubcut-" + props.name}
      id={props.name}
      width={props.width}
      height={props.height}
      x={props.x}
      y={props.y}
    >
      <rect
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        style={props.defaultStyle}
      />
      <text
        x={props.x}
        y={props.y + props.height}
        style={{ fontSize: fontsize }}
      >
        {props.name}
      </text>
    </g>
  );
}
