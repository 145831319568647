import React from "react";
import "../../css/styles.css";
import "../../css/quiltCalcStyles.css";
import "../../css/fabricSelectStyles.css";
import { FabricSelect, FabricSettings } from "./FabricSelect/FabricSelect.js";
import {
  BlockComp,
  CompTypes,
  InputSettings as CompSettings,
} from "../BlockComp/BlockComp.js";
import { v4 as uuidv4 } from "uuid";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { calcFabricNeeded, printFabricEst } from "../../utils/calcUtils.js";
import { Helmet } from "react-helmet";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

/*BlockForm Component Summary
This component sets the stage for two lists: fabrics and
quilt block components. Fabrics can be selected with a color 
selector and named by the user. Components have a list of 
settings to determine the fabric needed for this quilt block
*/
export class BlockForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comps: props.comps,
      fabricObjects: props.fabricObjects,
      displayFabricEst: props.displayFabricEst,
      displayFabricCuts: props.displayFabricCuts,
      blockRepeat: props.blockRepeat,
      displayCalcError: false,
    };
    this.cutRecRef = React.createRef();
    this.cutRecWrapperRef = React.createRef();
  }

  handleBlockRepeatChange = (event) => {
    this.handleUpdateToCalcs();
    this.setState({ blockRepeat: event.target.value });
    ReactGA.event({
      category: "QuiltCalc",
      action: "setting-change",
      label: "block-repeat-" + event.target.value, // optional
    });
  };

  handleUpdateToCalcs = () => {
    this.setState({ displayFabricEst: false });
    this.setState({ displayFabricCuts: false });
    this.setState({ displayCalcError: false });
    let updatedFabricObjects = this.state.fabricObjects;
    updatedFabricObjects.forEach((f) => {
      f.cuts = [];
      f.compData = [];
      f.fabricEst = [];
      f.cutRecs = [];
      return f;
    });
    this.setState({ fabricObjects: updatedFabricObjects });
  };

  /* Function Summary
  Adds a fabric object to the BlockForm's state fabricObjects.
  Because there is a separate function to display the fabric types,
  the fabrics shown will update as well
   */
  addFabricType = () => {
    this.handleUpdateToCalcs();
    this.setState({
      fabricObjects: this.state.fabricObjects.concat({
        id: uuidv4(),
        hexCode: "#00a3cc",
        friendlyId: String.fromCharCode(
          64 + this.state.fabricObjects.length + 1,
        ),
        fabricEst: [],
        widthOfFabric: 42,
        cutDisplayWidth: 0,
        compData: [],
        cutRecs: [],
        cuts: [],
      }),
    });
    ReactGA.event({
      category: "QuiltCalc",
      action: "setting-change",
      label: "add-fabric", // optional
    });
    console.log("Fabric Objects: " + this.state.fabricObjects);
  };

  /* Function Summary
  Sends a list of options to the components to reference when
  the user selects which fabrics for each element of the BlockComp
   */
  returnFabricOptionList = () => {
    return this.state.fabricObjects.map((f) => {
      return (
        <option key={f.id} value={f.id}>
          {f.friendlyId}
        </option>
      );
    });
  };

  /* Function Summary
  Handles all user generated change to the fabric object
   */
  handleUpdateToFabric = (id, input, updateValue) => {
    this.handleUpdateToCalcs();

    let obj = {};
    let updatedObjects = this.state.fabricObjects.map((f) => {
      if (f.id === id) {
        obj = f;
        console.log("Comp " + id + " updated " + input + " to " + updateValue);
        ReactGA.event({
          category: "QuiltCalc",
          action: "setting-change",
          label: "fabric-" + input, // optional
          value: updateValue,
        });
        switch (input) {
          case FabricSettings.friendlyId:
            obj.friendlyId = updateValue;
            return obj;
          case FabricSettings.hexCode:
            obj.hexCode = updateValue;
            return obj;
          case FabricSettings.WOF:
            obj.widthOfFabric = updateValue;
            return obj;
          default:
            //todo better error handling
            console.log(
              "Error in input type sent to handleUpdateToFabric. Input: " +
                input,
            );
            return obj;
        }
      } else {
        // The rest haven't changed
        return f;
      }
    });
    this.setState({ fabricObjects: updatedObjects });
  };

  /* Function Summary
  Retrieves the fabric color based on the fabricId for the BlockComp
  to display
  */
  returnFabricColor = (fabricId) => {
    if (fabricId === "" || fabricId == null) {
      return "#ffffff";
    }
    let obj = this.state.fabricObjects.find((f) => f.id === fabricId);
    //todo handle error
    console.log("Get Fabric Color " + obj);
    return obj.hexCode;
  };

  /* Function Summary
  Adds a BlockComp to the BlockForm's state comps.
  Because there is a separate function to display the comps,
  the comps shown will update as well
   */
  addBlockComp = () => {
    this.handleUpdateToCalcs();

    ReactGA.event({
      category: "QuiltCalc",
      action: "setting-change",
      label: "add-comp", // optional
    });
    this.setState({
      comps: this.state.comps.concat({
        id: uuidv4(),
        displayError: false,
        height: 0,
        width: 0,
        repeat: 1,
        cornerWidth: 0,
        cornerCount: 1,
        compType: CompTypes.FlyingGeese,
        posColors: [
          {
            pos: "fill",
            friendlyPosName: "Fill ",
            matchingCompTypes: "rect snowball",
            fabricId: "",
          },
          {
            pos: "bottomTri",
            friendlyPosName: "Bottom Triangle ",
            matchingCompTypes: "hst",
            fabricId: "",
          },
          {
            pos: "topTri",
            friendlyPosName: "Top Triangle ",
            matchingCompTypes: "hst",
            fabricId: "",
          },
          {
            pos: "left",
            friendlyPosName: "Left ",
            matchingCompTypes: "geese",
            fabricId: "",
          },
          {
            pos: "middle",
            friendlyPosName: "Middle ",
            matchingCompTypes: "geese",
            fabricId: "",
          },
          {
            pos: "right",
            friendlyPosName: "Right ",
            matchingCompTypes: "geese",
            fabricId: "",
          },
          {
            pos: "corner",
            friendlyPosName: "Corner ",
            matchingCompTypes: "snowball",
            fabricId: "",
          },
        ],
      }),
    });
    console.log("Block Comps: " + this.state.comps);
  };

  /* Function Summary
  Removes a BlockComp to the BlockForm's state comps.
  This allows an added BlockComp to be excluded from the overall
  fabric need calculator
   */
  deleteBlockComp = (blockId) => {
    this.handleUpdateToCalcs();

    //delete the comp from comps
    let updatedObjects = this.state.comps.filter((c) => c.id !== blockId);
    this.setState({ comps: updatedObjects });

    //remove the comp from fabricEst, if there
    updatedObjects = this.state.fabricObjects;
    updatedObjects.forEach((f) => {
      f.fabricEst = f.fabricEst.filter((e) => e[0] !== blockId);
    });
    this.setState({ fabricObjects: updatedObjects });
    ReactGA.event({
      category: "QuiltCalc",
      action: "setting-change",
      label: "delete-comp", // optional
    });
  };

  /* Function Summary
  Handles all user generated change to the comp object
   */
  handleUpdateToCompInput = (id, input, updateValue) => {
    this.handleUpdateToCalcs();

    let updatedObjects = this.state.comps;
    let obj = this.state.comps.find((c) => c.id === id);
    ReactGA.event({
      category: "QuiltCalc",
      action: "setting-change",
      label: "comp-" + input, // optional
      value: updateValue,
    });
    switch (input) {
      case CompSettings.Repeat:
        obj.repeat = updateValue;
        break;
      case CompSettings.Height:
        obj.height = updateValue;
        break;
      case CompSettings.Width:
        obj.width = updateValue;
        break;
      case CompSettings.CornerWidth:
        obj.cornerWidth = updateValue;
        break;
      case CompSettings.CornerCount:
        obj.cornerCount = updateValue;
        break;
      case CompSettings.CompType:
        obj.compType = updateValue;
        break;
      case CompSettings.PosColors:
        obj.posColors = updateValue;
        break;
      default:
        //todo better error handling
        console.log(
          "Error in input type sent to handleUpdateToCompInput. Input: " +
            input,
        );
        break;
    }
    updatedObjects.map((c) => {
      if (c.id === id) {
        return obj;
      } else {
        return c;
      }
    });
    console.log(input + " set to: " + updateValue);
    this.setState({ comps: updatedObjects });
  };

  componentDidMount() {
    this.updateWrapperDimensions();
  }

  componentDidUpdate(prevProps) {
      if (prevProps.svgData !== this.props.svgData) {
          this.updateWrapperDimensions();
      }
  }

  updateWrapperDimensions() {
      const svgElement = this.cutRecRef.current;
      const wrapperElement = this.cutRecWrapperRef.current;

      if (svgElement && wrapperElement) {
          // Wait for the SVG to render fully, then calculate dimensions
          setTimeout(() => {
            const svgRect = svgElement.getBoundingClientRect();
            const svgWidth = svgRect.width || 800;
            const svgHeight = svgRect.height || 420;

            wrapperElement.style.width = `${svgWidth}px`;
            wrapperElement.style.height = `${svgHeight}px`;
          }, 0);
      }
  }

  handleCalcReturn() {
    //if any inputs missing, kindly fail
    let foundIssue = false;

    let updatedComps = this.state.comps.map((c) => {
      let updatedC = c;

      //check if fabric selections made
      let matchingPosColors = c.posColors.filter((p) =>
        p.matchingCompTypes.includes(this.state.compType),
      );
      matchingPosColors.forEach((p) => {
        if (p.fabricId === "" || p.fabricId == undefined) {
          foundIssue = true;
          updatedC.displayError = true;
        }
      });

      //test if reg inputs are filled in
      if (
        c.repeat == undefined ||
        c.repeat === 0 ||
        c.height == undefined ||
        c.height === 0 ||
        c.width == undefined ||
        c.width === 0 ||
        (c.compType === CompTypes.Snowball &&
          (c.cornerWidth == undefined ||
            c.cornerWidth === 0 ||
            c.cornerCount == undefined ||
            c.cornerCount <= 0 ||
            c.cornerCount > 4))
      ) {
        foundIssue = true;
        updatedC.displayError = true;
        return c;
      } else {
        return c;
      }
    });
    if (foundIssue) {
      //return and display error
      this.setState({ comps: updatedComps });
      this.setState({ displayCalcError: true });
    } else {
      //get calculations for each fabric object
      this.setState({
        fabricObjects: calcFabricNeeded(
          this.state.comps,
          this.state.fabricObjects,
          this.state.blockRepeat,
        ),
      });
      //display calculations
      this.setState({ displayFabricEst: true });
    }
  }

  handleDisplayColumns() {
    this.setState({
      displayFabricCuts: true,
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Quilt Fabric Calculator | Gibson Threads</title>
          <link rel="canonical" href={"https://gibsonthreads.com/quiltcalc"} />
          <meta
            name="description"
            content="Estimate the fabric needed for your next quilt project based on the elements that make up your quilt blocks."
          />
          <meta
            property="og:title"
            content="Quilt Fabric Calculator | Gibson Threads"
          />
          <meta
            property="og:description"
            content="Estimate the fabric needed for your next quilt project based on the HST's, rectangles, flying geese and snowballs that make up your quilt blocks."
          />
          <meta
            property="og:image"
            content={
              process.env.REACT_APP_URL +
              "static/media/fabricSelectEx.4f370e3f180543d4c498.png"
            }
          />
        </Helmet>
        <NavBar displayCenter={false} title="Quilt Fabric Calculator" />
        <div className="quiltCalcForm">
          <div className="basicBackground">
            <hr />
            <p className="description">
              Estimate your fabric need based on each block. Increase the block
              repeat count for fabric estimates on multiple blocks.
            </p>
            <div>
              <div className="description">
                <label>
                  Quilt Block Repeat:
                  <input
                    id="blockRepeatInput"
                    type="number"
                    placeholder={this.state.blockRepeat}
                    onChange={this.handleBlockRepeatChange}
                  />
                </label>
              </div>
              <div>
                <button className="fabricButtons" onClick={this.addFabricType}>
                  Add Fabric
                </button>
              </div>
              <div className="fabricSelectList">
                {this.state.fabricObjects?.map((f) => {
                  return (
                    <FabricSelect
                      key={f.id}
                      friendlyId={f.friendlyId}
                      id={f.id}
                      hexCode={f.hexCode}
                      widthOfFabric={f.widthOfFabric}
                      updateFabricSettings={this.handleUpdateToFabric}
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <button onClick={() => this.addBlockComp()}>
                Add Quilt Block Component
              </button>
              <Accordion elevation={0} className="accordian">
                <AccordionSummary
                  id="panel-header"
                  aria-controls="panel-content"
                >
                  Additional Details
                </AccordionSummary>
                <AccordionDetails className="body">
                  <ul>
                    <li>
                      A quilt block component is an element inside a quilt
                      block, such as a rectangle or half square triangle.
                      It&apos;s often repeated inside the quilt block for a
                      symmetrical appearence.
                    </li>
                    <li>
                      All measurements are in inches and referencing the
                      unfinished block component size.
                    </li>
                    <li>
                      Width of elements align with the straight grain (parallel
                      to the selvage).
                    </li>
                    <li>
                      In general, the more efficient use of fabric has the
                      larger side align with the cross grain (perpendicular to
                      the selvage).
                    </li>
                  </ul>
                  <a href="https://www.gibsonthreads.com/blog/straight-grain-vs-cross-grain-quilting">
                    For more details on fabric grain, please see this reference.
                  </a>
                </AccordionDetails>
              </Accordion>
            </div>
            {this.state.comps?.map((c) => {
              return (
                <BlockComp
                  key={c.id}
                  id={c.id}
                  height={c.height}
                  width={c.width}
                  cornerWidth={c.cornerWidth}
                  cornerCount={c.cornerCount}
                  displayError={c.displayError}
                  repeat={c.repeat}
                  compType={c.compType}
                  posColors={c.posColors}
                  fabricObjects={this.state.fabricObjects}
                  returnFormFabricList={this.returnFabricOptionList}
                  returnFormFabricColor={this.returnFabricColor}
                  deleteBlockComp={this.deleteBlockComp}
                  handleUpdateToCompInput={this.handleUpdateToCompInput}
                />
              );
            })}
            {this.state.displayCalcError && (
              <div className="formError">
                There has been an issue with calculations. Please check your
                components and fabric selections.
              </div>
            )}
            {this.state.comps?.length > 0 && (
              <div className="formSubmit">
                <button
                  disabled={this.state.displayFabricEst}
                  onClick={() => {
                    this.handleCalcReturn();
                  }}
                >
                  Calculate Fabric Needed
                </button>
              </div>
            )}
            <div
              className="fabricSelectList"
              style={{ display: this.state.displayFabricEst ? "grid" : "none" }}
            >
              {this.state.fabricObjects?.map((f) => {
                return (
                  <div key={f.id} className="fabricSelect">
                    <div>
                      <span className="inline-h2">{f.friendlyId}</span>
                      <svg
                        width="25px"
                        height="25px"
                        style={{ paddingLeft: "5px" }}
                      >
                        <rect
                          width="25px"
                          height="25px"
                          style={{ fill: f.hexCode }}
                        />
                      </svg>
                    </div>

                    <div>Width of Fabric: {f.widthOfFabric}</div>
                    <div>
                      Esimated Fabric Needed:{" "}
                      {printFabricEst(f, this.state.displayFabricEst)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="fabricEstGrid"
              style={{
                display: this.state.displayFabricEst ? "grid" : "none",
              }}
            >
              {this.state.fabricObjects?.map((f) => {
                if (f.fabricEst.length > 0) {
                  return (
                    <div key={f.id} className="fabCutRecs">
                      <div className="inline-h3">
                        Cut Recommendations: Fabric {f.friendlyId}
                      </div>
                      <div className="writtenCutRecommendations">
                        {f.writtenCutInsts}
                      </div>
                      <div
                        height="100%"
                        width="100%"
                        ref={this.cutRecWrapperRef}
                        className="cutRecsWrapper"
                      >
                        <svg
                          className="cutRecs"
                          height="100%"
                          width="100%"
                          ref={this.cutRecRef}
                          preserveAspectRatio="xMidYMid none"
                          viewBox={f.viewbox}
                        >
                          {f.cutRecs}
                        </svg>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
