import React from "react";
import "../../css/styles.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import ApiOutlinedIcon from "@mui/icons-material/ApiOutlined";
import CropSquareRoundedIcon from "@mui/icons-material/CropSquareRounded";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import FilterNoneOutlinedIcon from "@mui/icons-material/FilterNoneOutlined";
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined";

export default function NavBar(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="default" data-testid="navbar-container">
        <Toolbar>
          <TemporaryDrawer />
          <h1 style={{ alignSelf: "center" }}>{props.title}</h1>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

function TemporaryDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "navigation",
                label: "home",
              })
            }
          >
            <ListItemIcon>
              <CottageOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/blog"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "navigation",
                label: "blog",
              })
            }
          >
            <ListItemIcon>
              <LibraryBooksOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Blog" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/about"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "navigation",
                label: "about",
              })
            }
          >
            <ListItemIcon>
              <ApiOutlinedIcon />
            </ListItemIcon>

            <ListItemText primary="About" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/quiltcalc"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "navigation",
                label: "quiltCalc",
              })
            }
          >
            <ListItemIcon>
              <CalculateOutlinedIcon />
            </ListItemIcon>

            <ListItemText primary="Fabric Yardage Calculator" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/quilt-binding-calculator"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "navigation",
                label: "bindingCalc",
              })
            }
          >
            <ListItemIcon>
              <CropSquareRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Binding Calculator" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/quilt-backing-calculator"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "navigation",
                label: "backingCalc",
              })
            }
          >
            <ListItemIcon>
              <FilterNoneOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Backing Calculator" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            component={Link}
            to="/color-palette-creator"
            onClick={() =>
              ReactGA.event({
                category: "Recirculation",
                action: "navigation",
                label: "colorPalette",
              })
            }
          >
            <ListItemIcon>
              <PaletteOutlinedIcon />
            </ListItemIcon>

            <ListItemText primary="Color Palette Generator" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <MenuIcon
        style={{ color: "#386123", paddingRight: 10 }}
        onClick={toggleDrawer(true)}
      >
        Open drawer
      </MenuIcon>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}