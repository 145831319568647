import React from "react";
import { CompTypes } from "../Components/BlockComp/BlockComp.js";
import { DisplayedSubcut } from "../Components/DisplayedCuts/DisplayedCuts.js";

export function printFabricEst(fabricObj, displayFabricEst) {
  if (!displayFabricEst) {
    return;
  }

  //get inches needed, turn to yardage
  let tempFabricObject = displayColumns(fabricObj);
  let yardage = parseFloat((tempFabricObject.cutDisplayWidth / 36).toFixed(4));

  console.log("Yardage estimate: " + yardage);
  let partialYardage = yardage - Math.floor(yardage);
  let yardageString = "";

  if (yardage - partialYardage !== 0) {
    yardageString = yardage - partialYardage;
  }

  //get fraction for partialYardage
  if (partialYardage > 0.75) {
    //round up
    return Math.floor(yardage) + 1 + " yards";
  } else if (partialYardage > 0.5) {
    return yardageString + " 3/4 yards";
  } else if (partialYardage > 0.25) {
    return yardageString + " 1/2 yards";
  } else if (partialYardage >= 0.125) {
    return yardageString + " 1/4 yards";
  } else if (partialYardage < 0.125 && partialYardage > 0) {
    return yardageString + " 1/8 yards";
  } else {
    return yardageString + " yards";
  }
}

export function printFabricEstHelperImperial(inches) {
  let yardage = parseFloat((inches / 36).toFixed(4));

  console.log("Yardage estimate: " + yardage);
  let partialYardage = yardage - Math.floor(yardage);
  let yardageString = "";

  if (yardage - partialYardage !== 0) {
    yardageString = yardage - partialYardage;
  }

  //get fraction for partialYardage
  if (partialYardage > 0.75) {
    //round up
    return Math.floor(yardage) + 1 + " yards";
  } else if (partialYardage > 0.67){
    return yardageString + " 3/4 yards";
  } else if (partialYardage > 0.5) {
    return yardageString + " 2/3 yards";
  } else if (partialYardage > 0.34) {
    return yardageString + " 1/2 yards";
  } else if (partialYardage > 0.25) {
    return yardageString + " 1/3 yards";
  } else if (partialYardage > 0.126) {
    return yardageString + " 1/4 yards";
  } else if (partialYardage <= 0.125 && partialYardage > 0) {
    return yardageString + " 1/8 yards";
  } else {
    return yardageString + " yards";
  }
}

export function printFabricEstHelperMetric(cm) {
  let meters = parseFloat((cm / 100).toFixed(4));

  console.log("Meter estimate: " + meters);
  let partialMeters = meters - Math.floor(meters);
  let meterageString = "";

  if (meters - partialMeters !== 0) {
    meterageString = meters - partialMeters;
  }

  //get fraction for partialMeterage
  if (partialMeters > 0.75) {
    //round up
    return Math.floor(meters) + 1 + " meters";
  } else if (partialMeters > 0.5) {
    return meterageString + " 3/4 meters";
  } else if (partialMeters > 0.33) {
    return meterageString + " 1/2 meters";
  } else if (partialMeters > 0.25) {
    return meterageString + " 1/3 meters";
  } else if (partialMeters > 0.125) {
    return meterageString + " 1/4 meters";
  } else if (partialMeters < 0.125 && partialMeters > 0) {
    return meterageString + " 1/8 meters";
  } else {
    return meterageString + " meters";
  }
}

function addToFabricEst(
  fabricObj,
  compId,
  cutHeight,
  cutWidth,
  cutName,
  repeat,
  fabricObjects,
) {
  console.log(
    "Entered add to fabric est: Fabric Id " +
      fabricObj +
      " , FabricEst " +
      cutHeight * cutWidth,
  );
  let updatedObjects = fabricObjects.map((f) => {
    if (f.id === fabricObj.id) {
      let obj = f;
      obj.fabricEst = [...f.fabricEst, [compId, cutHeight * cutWidth]];
      obj.compData = obj.compData.concat({
        name: cutName,
        width: cutHeight,
        height: cutWidth,
        repeat: repeat,
      });
      console.log(
        "Fabric Id " + fabricObj.id + " updated to " + cutHeight * cutWidth,
      );
      return obj;
    } else {
      // The rest haven't changed
      return f;
    }
  });
  return updatedObjects;
}

/*
Props: comps, fabricObjects, blockRepeat */
export function calcFabricNeeded(comps, fabricObjects, blockRepeat) {
  console.log("---Calulating Fabric Need---");
  let fabricObjRef1, fabricObjRef2, fabricObjRef3;
  let repeatFormula, remainder;
  let updatedFabricObjects = fabricObjects;

  //estimate fabric need
  /*For each component, add to the fabric type the estimated 
    fabric need in square inches */
  comps.forEach((c) => {
    let cIndex = comps.indexOf(c) + 1;

    switch (c.compType) {
      case CompTypes.Rect:
        console.log("---Case: Rect---");
        //only uses one fabric type
        fabricObjRef1 = fabricObjects.find(
          (f) => f.id === c.posColors.find((p) => p.pos === "fill").fabricId,
        );

        //fill state fabric estimate based on response
        console.log("Element fabric id: " + fabricObjRef1);
        console.log("Element repeat: " + typeof c.repeat);

        updatedFabricObjects = addToFabricEst(
          fabricObjRef1,
          c.id,
          c.height,
          c.width,
          fabricObjRef1.friendlyId + cIndex,
          c.repeat * blockRepeat,
          fabricObjects,
        );
        return;

      case CompTypes.HST:
        console.log("---Case: HST---");
        remainder = c.repeat * blockRepeat;
        fabricObjRef1 = fabricObjects.find(
          (f) => f.id === c.posColors.find((p) => p.pos === "topTri").fabricId,
        );
        fabricObjRef2 = fabricObjects.find(
          (f) =>
            f.id === c.posColors.find((p) => p.pos === "bottomTri").fabricId,
        );

        //uses two fabric types equally
        //if > eight, return magic eight formula
        if (remainder >= 8) {
          //how many magic 8's
          repeatFormula = Math.floor(remainder / 8);
          remainder = remainder - repeatFormula * 8;

          updatedFabricObjects = addToFabricEst(
            fabricObjRef1,
            c.id,
            c.height * 2 + 2,
            c.height * 2 + 2,
            fabricObjRef1.friendlyId + cIndex,
            repeatFormula,
            fabricObjects,
          );
          updatedFabricObjects = addToFabricEst(
            fabricObjRef2,
            c.id,
            c.height * 2 + 2,
            c.height * 2 + 2,
            fabricObjRef2.friendlyId + cIndex,
            repeatFormula,
            fabricObjects,
          );
        }
        //if remainder >= four, return magic four formula
        else if (remainder >= 4) {
          repeatFormula = Math.floor(remainder / 4);
          remainder = remainder - repeatFormula * 4;

          updatedFabricObjects = addToFabricEst(
            fabricObjRef1,
            c.id,
            Math.ceil(c.height / 0.64),
            Math.ceil(c.height / 0.64),
            fabricObjRef1.friendlyId + cIndex,
            repeatFormula,
            fabricObjects,
          );
          updatedFabricObjects = addToFabricEst(
            fabricObjRef2,
            c.id,
            Math.ceil(c.height / 0.64),
            Math.ceil(c.height / 0.64),
            fabricObjRef2.friendlyId + cIndex,
            repeatFormula,
            fabricObjects,
          );
        }
        //if remainder >= two, return two formula
        else if (remainder >= 1) {
          repeatFormula = Math.floor(remainder / 2);
          remainder = remainder - repeatFormula * 2;

          updatedFabricObjects = addToFabricEst(
            fabricObjRef1,
            c.id,
            c.height + 1,
            c.height + 1,
            fabricObjRef1.friendlyId + cIndex,
            repeatFormula,
            fabricObjects,
          );
          updatedFabricObjects = addToFabricEst(
            fabricObjRef2,
            c.id,
            c.height + 1,
            c.height + 1,
            fabricObjRef2.friendlyId + cIndex,
            repeatFormula,
            fabricObjects,
          );
        }
        return;
      case CompTypes.FlyingGeese:
        console.log("---Case: Geese---");
        fabricObjRef1 = fabricObjects.find(
          (f) => f.id === c.posColors.find((p) => p.pos === "middle").fabricId,
        );
        fabricObjRef2 = fabricObjects.find(
          (f) => f.id === c.posColors.find((p) => p.pos === "right").fabricId,
        );
        fabricObjRef3 = fabricObjects.find(
          (f) => f.id === c.posColors.find((p) => p.pos === "left").fabricId,
        );

        //if the right and left color don't match, 3 color types
        if (fabricObjRef2.id !== fabricObjRef3.id) {
          //must do one at a time method for each repeat
          updatedFabricObjects = addToFabricEst(
            fabricObjRef1,
            c.id,
            c.height,
            c.width,
            fabricObjRef1.friendlyId + cIndex,
            c.repeat * blockRepeat,
            fabricObjects,
          );
          updatedFabricObjects = addToFabricEst(
            fabricObjRef2,
            c.id,
            c.height,
            c.height,
            fabricObjRef2.friendlyId + cIndex,
            c.repeat * blockRepeat,
            fabricObjects,
          );
          updatedFabricObjects = addToFabricEst(
            fabricObjRef3,
            c.id,
            c.height,
            c.height,
            fabricObjRef3.friendlyId + cIndex,
            c.repeat * blockRepeat,
            fabricObjects,
          );
        } else {
          //otherwise, two color types
          remainder = c.repeat * blockRepeat;

          //if repeat 4+ times, recommend the 4-at-a-time-method
          if (remainder >= 4) {
            repeatFormula = Math.floor(remainder / 4);
            remainder = remainder - repeatFormula * 4;

            updatedFabricObjects = addToFabricEst(
              fabricObjRef1,
              c.id,
              Number.parseFloat(c.width) + 1.25,
              Number.parseFloat(c.width) + 1.25,
              fabricObjRef1.friendlyId + cIndex,
              repeatFormula,
              fabricObjects,
            );
            updatedFabricObjects = addToFabricEst(
              fabricObjRef2,
              c.id,
              Number.parseFloat(c.height) + 0.875,
              Number.parseFloat(c.height) + 0.875,
              fabricObjRef2.friendlyId + cIndex,
              repeatFormula * 4,
              fabricObjects,
            );
          }
          //if remainder >= one, recommend one-at-a-time-method
          else if (remainder >= 1) {
            updatedFabricObjects = addToFabricEst(
              fabricObjRef1,
              c.id,
              c.height,
              c.width,
              fabricObjRef1.friendlyId + cIndex,
              remainder * blockRepeat,
              fabricObjects,
            );
            updatedFabricObjects = addToFabricEst(
              fabricObjRef2,
              c.id,
              c.height,
              c.height,
              fabricObjRef2.friendlyId + cIndex,
              remainder * blockRepeat * 2,
              fabricObjects,
            );
          }
        }
        return;
      case CompTypes.Snowball:
        console.log("---Case: Snowball---");
        //uses 2 fabric types
        fabricObjRef1 = fabricObjects.find(
          (f) => f.id === c.posColors.find((p) => p.pos === "fill").fabricId,
        );
        fabricObjRef2 = fabricObjects.find(
          (f) => f.id === c.posColors.find((p) => p.pos === "corner").fabricId,
        );

        //fill state fabric estimate based on response
        updatedFabricObjects = addToFabricEst(
          fabricObjRef1,
          c.id,
          c.height,
          c.width,
          fabricObjRef1.friendlyId + cIndex,
          c.repeat * blockRepeat,
          fabricObjects,
        );
        updatedFabricObjects = addToFabricEst(
          fabricObjRef2,
          c.id,
          c.cornerWidth,
          c.cornerWidth,
          fabricObjRef2.friendlyId + cIndex,
          c.cornerCount * c.repeat * blockRepeat,
          fabricObjects,
        );
        return;
      default:
        return;
    }
  });
  return updatedFabricObjects;
}

function fillColumnsHelper(compDataObject, currentCuts, WOF) {
  let updatedCuts = currentCuts;

  //for each element (repeats considered, determine column placement)
  for (let i = 0; i < compDataObject.repeat; i++) {
    if (updatedCuts.length < 1) {
      //add element to cut
      updatedCuts = [
        {
          stripWidth: compDataObject.width,
          stripRepeat: 1,
          inchesLeft: WOF - compDataObject.height,
          subcuts: [
            {
              name: compDataObject.name,
              height: compDataObject.height,
              width: compDataObject.width,
              repeat: 1,
            },
          ],
          isRepeat: false,
        },
      ];
    } else {
      let elementPlaced = false;
      let iteratingCuts = updatedCuts;
      iteratingCuts.forEach((c) => {
        if (
          /*if column is wider than element and
          there is height left in the column, place element*/
          c.stripWidth >= compDataObject.width &&
          c.inchesLeft >= compDataObject.height &&
          elementPlaced === false
        ) {
          let tempCol = c;
          //element can fit, place
          elementPlaced = true;

          //if cut column has a repeat, separate repeats before updating column
          if (c.repeat > 1) {
            let reducedTempCol = tempCol;
            reducedTempCol.repeat = reducedTempCol.repeat - 1;
            updatedCuts = updatedCuts.concat(reducedTempCol);
          }

          //does the subcut already exist within the column
          let foundSubCut = tempCol.subcuts.find(
            (e) => e.name === compDataObject.name,
          );
          if (foundSubCut !== undefined) {
            tempCol.subcuts.map((s) => {
              if (s === foundSubCut) {
                foundSubCut.repeat = foundSubCut.repeat + 1;
                return foundSubCut;
              } else {
                return s;
              }
            });
          } else {
            tempCol.subcuts = tempCol.subcuts.concat({
              name: compDataObject.name,
              height: compDataObject.height,
              width: compDataObject.width,
              repeat: 1,
            });
          }
          tempCol.inchesLeft = tempCol.inchesLeft - compDataObject.height;
          updatedCuts = updatedCuts.map((f) => {
            if (f === c) {
              return tempCol;
            } else {
              return f;
            }
          });
          console.log(
            compDataObject.name +
              " Block placed " +
              c.index +
              " Width: " +
              compDataObject.width,
          );
        }
      });
      //if element still not placed, add a new column
      if (elementPlaced === false) {
        console.log(
          "Filling next column: Index " +
            updatedCuts.length +
            " block " +
            compDataObject.name,
        );
        updatedCuts = updatedCuts.concat({
          stripWidth: compDataObject.width,
          stripRepeat: 1,
          inchesLeft: WOF - compDataObject.height,
          subcuts: [
            {
              name: compDataObject.name,
              height: compDataObject.height,
              width: compDataObject.width,
              repeat: 1,
            },
          ],
          isRepeat: false,
        });
      }
    }
  }

  //dedupe cut columns
  /* For each value in the array
  Determine if all other values of array are duplicates, counting
  Update stripRepeat with this count
  Only return non-repeats */
  for (let i = 0; i < updatedCuts.length; i++) {
    let stripCount = updatedCuts[i].stripRepeat;
    for (let j = i + 1; j < updatedCuts.length; j++) {
      if (
        updatedCuts[i].stripWidth === updatedCuts[j].stripWidth &&
        updatedCuts[i].inchesLeft === updatedCuts[j].inchesLeft &&
        updatedCuts[i].subcuts.join() === updatedCuts[j].subcuts.join() &&
        !updatedCuts[j].isRepeat
      ) {
        stripCount = stripCount + 1;
        updatedCuts[j].isRepeat = true;
      }
    }
    updatedCuts[i].stripRepeat = stripCount;
  }
  return updatedCuts.filter((f) => !f.isRepeat);
}

function getRelativeLuminance(color) {
  const [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16) / 255);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function getContrastRatio(color1, color2) {
  const luminance1 = getRelativeLuminance(color1);
  const luminance2 = getRelativeLuminance(color2);
  const lighter = Math.max(luminance1, luminance2);
  const darker = Math.min(luminance1, luminance2);
  return (lighter + 0.05) / (darker + 0.05);
}

function getBorderColor(fillColor) {
  const blackContrast = getContrastRatio(fillColor, "#000000");
  const whiteContrast = getContrastRatio(fillColor, "#FFFFFF");
  const thresholdContrast = 1; // Define a threshold contrast ratio here
  if (blackContrast >= thresholdContrast && blackContrast > whiteContrast) {
    return "#000000";
  } else if (
    whiteContrast >= thresholdContrast &&
    whiteContrast > blackContrast
  ) {
    return "#FFFFFF";
  } else {
    return "#000000"; // Default to black border color if neither black nor white is appropriate
  }
}

function displayColumns(fabricObject) {
  const highContrastBorder = getBorderColor(fabricObject.hexCode);

  let defaultStyle = {
    fill: fabricObject.hexCode,
    stroke: highContrastBorder,
    strokeWidth: "0.01em",
  };

  let columnWidthStart = 0; //keep track of starting point in each column
  let columnHeightStart = 0; //keeping track of the element's height within the column
  let tempDisplayCols = []; //keeping track of display columns
  let tempDisplayElObjects = []; //keeping track of the elements within the display columns

  let tempWrittenCutInst = [];

  /*Before displaying, we must fill the columns */
  console.log("---- Entered Filled Columns ----");

  //make width the wider of the two block measurements
  /*let sortedData = fabricObject.compData.map((d) => {
    if (d.height > d.width) {
      let tempMeas = d.width;
      d.width = d.height;
      d.height = tempMeas;
    }
    return d;
  });*/

  //sort the data points with widest first
  let sortedData = fabricObject.compData.sort(function (a, b) {
    return b.width - a.width;
  });

  /*To avoid constantly rerendering the component
    as we fill in the columns (by setting the state), use
    a tempFilledCols */
  let tempFilledCols = [];
  sortedData.forEach((d) => {
    tempFilledCols = fillColumnsHelper(
      d,
      tempFilledCols,
      fabricObject.widthOfFabric,
    );
  });
  console.log(
    "---- Columns filled. Num cols: " + (tempFilledCols.length + 1) + " ----",
  );

  let tempCutDisplayWidth = 0;
  let pxScale = tempFilledCols.length >= 4 ? 10 : 5;

  tempFilledCols.forEach((c) => {
    //reset columnHeightStart with each column
    columnHeightStart = 0;
    //reset displayEls;
    tempDisplayElObjects = [];
    let tempSubcutInsts = [];
    tempCutDisplayWidth = tempCutDisplayWidth + c.stripWidth * c.stripRepeat;

    Array.prototype.forEach.call(c.subcuts, (s) => {
      tempSubcutInsts = tempSubcutInsts.concat(
        <li>
          {s.repeat} {s.name}&apos;s: {s.width}x{s.height}in
        </li>,
      );
      for (let i = 0; i < s.repeat; i++) {
        tempDisplayElObjects = tempDisplayElObjects.concat({
          name: s.name,
          width: Number.parseFloat(s.width),
          height: Number.parseFloat(s.height),
          y: columnHeightStart * pxScale,
        });
        columnHeightStart = columnHeightStart + Number.parseFloat(s.height);
      }
    });

    console.log(
      "Adding column to display: " + c.index + " of " + tempFilledCols.length,
    );

    for (let i = 0; i < c.stripRepeat; i++) {
      tempDisplayCols = tempDisplayCols.concat(
        <g>
          <rect
            x={columnWidthStart * pxScale}
            width={c.stripWidth * pxScale}
            height={fabricObject.widthOfFabric * pxScale}
            style={defaultStyle}
          />
          {tempDisplayElObjects.map((elObj) => {
            return (
              <DisplayedSubcut
                key={elObj.name}
                name={elObj.name}
                width={Number.parseFloat(elObj.width) * pxScale}
                height={Number.parseFloat(elObj.height) * pxScale}
                x={columnWidthStart * pxScale}
                y={Number.parseFloat(elObj.y)}
                defaultStyle={defaultStyle}
              />
            );
          })}
        </g>,
      );
      columnWidthStart = columnWidthStart + c.stripWidth;
    }
    tempWrittenCutInst = tempWrittenCutInst.concat(
      <div className="wofCutInstructions">
        Cut {c.stripRepeat} {c.stripWidth}in WOF strip, subcut into:
        <ul>{tempSubcutInsts}</ul>
      </div>,
    );

    //add to columnWidthStart
    //columnWidthStart = columnWidthStart + c.stripWidth;
  });
  fabricObject.cutDisplayWidth = tempCutDisplayWidth;
  fabricObject.cutRecs = tempDisplayCols;
  fabricObject.writtenCutInsts = tempWrittenCutInst;

  fabricObject.viewbox =
    "0 0 " +
    (tempCutDisplayWidth * pxScale).toString() +
    " " +
    (fabricObject.widthOfFabric * pxScale).toString();

  return fabricObject;
}

//Little Calc Territory

export function getBindingLength(
  quiltHeight,
  quiltWidth,
  grainDir,
  widthOfFabric,
  bindingWidth,
) {
  /* Binding length in (in/cm) is the perimeter of the quilt
        plus seam allowance for each column of fabric pieced together.
        Seam allowance is double the width of the binding*/
  let bindingLength = 2 * quiltHeight + 2 * quiltWidth;
  if (grainDir === "cross") {
    bindingLength = Math.ceil(
      bindingLength + (bindingLength / widthOfFabric) * bindingWidth * 2,
    );
  } else {
    bindingLength = Math.ceil(
      bindingLength + (bindingWidth / widthOfFabric) * bindingWidth * 2,
    );
  }
  return bindingLength;
}
