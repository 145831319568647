import React, { useState } from "react";
import "../../css/styles.css";
import "../../css/quiltCalcStyles.css";
import NavBar from "../NavBar/NavBar.js";
import { Footer } from "../Footer/Footer.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import {
  printFabricEstHelperImperial,
  printFabricEstHelperMetric,
} from "../../utils/calcUtils.js";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import backingWidthDiagram1 from "../../Assets/BackingDiagram_WidthWiseGrain.png";
import backingWidthDiagram2 from "../../Assets/BackingDiagram_LengthWiseGrain.png";

export function LittleCalc(props) {
  let pageTitle, pageDescription, imgSource, content, slug;

  if (props.type === "binding") {
    pageTitle = "Quilt Binding Calculator";
    pageDescription =
      "Calculate the fabric needed for your quilt binding in yards or meters.";
    imgSource = "";
    content = <BindingCalc />;
    slug = "/quilt-binding-calculator";
  } else {
    pageTitle = "Quilt Backing Calculator";
    pageDescription =
      "Calculate the fabric needed for your quilt backing in yards or meters.";
    imgSource = "";
    content = <BackingCalc />;
    slug = "/quilt-backing-calculator";
  }

  return (
    <div>
      <Helmet>
        <title>{pageTitle} | Gibson Threads</title>
        <link rel="canonical" href={"https://gibsonthreads.com" + slug} />
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content="{pageTitle} | Gibson Threads" />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={imgSource} />
      </Helmet>
      <NavBar displayCenter={false} title={pageTitle} />
      <div className="quiltCalcForm">
        {content}
        <Footer />
      </div>
    </div>
  );
}

function BindingCalc() {
  const [returnValue, setReturnValue] = useState("");
  const [unitMeasure, setUnitMeasure] = useState("imperial");

  const [quiltHeight, setQuiltHeight] = useState(0);
  const [quiltWidth, setQuiltWidth] = useState(0);
  const [bindingWidth, setBindingWidth] = useState(0);
  const [widthOfFabric, setWidthOfFabric] = useState(42);
  const [grainDir, setgrainDir] = useState("cross");
  const [cutCount, setCutCount] = useState(0);
  const [lengthNeeded, setLengthNeeded] = useState(0);

  let unitDenotion = unitMeasure === "imperial" ? "(in)" : "(cm)";

  function handleSettingChange(event, setting) {
    setReturnValue("");
    switch (setting) {
      case "wof":
        setWidthOfFabric(event.target.value);
        break;
      case "height":
        setQuiltHeight(event.target.value);
        break;
      case "width":
        setQuiltWidth(event.target.value);
        break;
      case "bindingWidth":
        setBindingWidth(event.target.value);
        break;
      case "grainDir":
        setgrainDir(event.target.value);
        break;
      default:
        break;
    }
    ReactGA.event({
      category: "BindingQuiltCalc",
      action: "setting-change",
      label: { setting }, // optional
    });
  }

  function returnFabricCalc() {
    //handle errors of empty inputs
    if (quiltHeight * quiltWidth * bindingWidth * widthOfFabric === 0) {
      setReturnValue("failure");
      return;
    }

    /* Binding length in (in/cm) is the perimeter of the quilt
        plus seam allowance for each column of fabric pieced together
        and seam allowance for mitered corners
        Seam allowance is double the width of the binding - determined in if statement below
        Mitered corners is bindingWidth*4corners*/
    let bindingLength = 2 * quiltHeight + 2 * quiltWidth + bindingWidth * 4;
    let estimatedFabric;
    if (grainDir === "cross") {
      //number of columns is number of WOF strips needed
      bindingLength = Math.ceil(
        bindingLength + Math.floor(bindingLength / widthOfFabric) * 0.5,
      );
      setCutCount(Math.ceil(bindingLength / widthOfFabric));
      estimatedFabric =
        unitMeasure === "imperial"
          ? printFabricEstHelperImperial(
              Math.ceil(bindingLength / widthOfFabric) * bindingWidth,
            )
          : printFabricEstHelperMetric(
              Math.ceil(bindingLength / widthOfFabric) * bindingWidth,
            );
    } else {
      //number of rows is number of binding widths fit in WOF
      //fabric needed optimizes to keep row count low while not wasting fabric

      //binding is the perimeter plus seam allowance between rows
      bindingLength = Math.ceil(
        bindingLength + Math.floor(widthOfFabric / bindingWidth) * 0.5,
      );

      //estimated fabric length is the binding need divided by number of rows
      //from estimatedFabric, get number of rows needed to be cut
      let rowWidth = bindingLength / Math.floor(widthOfFabric / bindingWidth);

      if (unitMeasure === "imperial") {
        estimatedFabric = printFabricEstHelperImperial(roundYardage(rowWidth));
        setCutCount(Math.ceil(bindingLength / roundYardage(rowWidth)));
      } else {
        estimatedFabric = printFabricEstHelperMetric(Math.ceil(rowWidth));
        setCutCount(bindingLength / rowWidth);
      }
    }
    setLengthNeeded(bindingLength);

    //catch errors that TS would catch
    estimatedFabric === "NaN yards"
      ? setReturnValue("failure")
      : setReturnValue(estimatedFabric);
  }

  /*
  Yardage is sold in 1/4 or 1/3 increments and at 1/8 yard
  This function takes in inches and rounds to the nearest acceptable yardage
  Assumes the inches are less than 1 whole yard
   */
  function roundYardage(inches) {
    let yardageEst = parseFloat((inches / 36).toFixed(4));
    if (yardageEst > 0.75) {
      //round up
      return 36; //full yard
    } else if (yardageEst > 0.66) {
      return 27; //3/4 yard
    } else if (yardageEst > 0.5) {
      return 24; //2/3 yard
    } else if (yardageEst > 0.33) {
      return 18; //1/2 yard
    } else if (yardageEst > 0.25) {
      return 12; //1/3 yard
    } else if (yardageEst > 0.125) {
      return 9; //1/4 yard
    } else if (yardageEst < 0.125 && yardageEst > 0) {
      return 4.5; //1/8 yard
    } else {
      return "error"; //TODO something else here probably
    }
  }

  //cut area is made up of cut lines and remainder
  function getCutLines(fcutCount, fcutDir) {
    let returnArray = [];
    for (let i = 0; i < fcutCount; i++) {
      if (fcutDir === "cross") {
        returnArray = returnArray.concat(
          <line
            x1={(1000 / cutCount) * (i + 1)}
            x2={(1000 / cutCount) * (i + 1)}
            y1="0"
            y2="500"
            style={{ stroke: "#000000", strokeWidth: 1 }}
          />,
        );
      } else {
        let cutSpace = (bindingWidth * cutCount * 500) / widthOfFabric;
        returnArray = returnArray.concat(
          <line
            x1="0"
            x2="1000"
            y1={(cutSpace / cutCount) * (i + 1)}
            y2={(cutSpace / cutCount) * (i + 1)}
            style={{ stroke: "#000000", strokeWidth: 1 }}
          />,
        );
      }
    }
    return returnArray;
  }

  return (
    <div className="formBackground littleCalc">
      <div>
        <select
          className="overrideMarginAuto"
          onChange={(e) => setUnitMeasure(e.target.value)}
          data-testid="unit"
        >
          <option default value="imperial">
            Yards
          </option>
          <option value="metric">Meters</option>
        </select>
      </div>
      <label className="">
        Width of Fabric {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={widthOfFabric}
          onChange={(e) => handleSettingChange(e, "wof")}
          data-testid="wof"
        ></input>
      </label>
      <label className="">
        Quilt Height {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltHeight}
          onChange={(e) => handleSettingChange(e, "height")}
          data-testid="height"
        ></input>
      </label>
      <label className="">
        Quilt Width {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltWidth}
          onChange={(e) => handleSettingChange(e, "width")}
          data-testid="width"
        ></input>
      </label>
      <label className="">
        Binding Width {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={bindingWidth}
          onChange={(e) => handleSettingChange(e, "bindingWidth")}
          data-testid="bindingWidth"
        ></input>
      </label>
      <div className="">
        <label>
          Binding Grain Direction
          <select
            onChange={(e) => handleSettingChange(e, "grainDir")}
            data-testid="grain"
          >
            <option default value="cross">
              Cross Grain
            </option>
            <option value="straight">Straight Grain</option>
          </select>
        </label>
      </div>
      <Accordion
        elevation={0}
        sx={{ "&:before": { height: "0px" } }}
        className="accordian"
      >
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          Fabric Grain Details
        </AccordionSummary>
        <AccordionDetails className="body">
          <p>
            Grain direction sets the orientation of the binding. Default is the
            crossgrain (perpendicular to the selvage).
            <br />
            <a href="https://www.gibsonthreads.com/blog/straight-grain-vs-cross-grain-quilting">
              For more details on fabric grain, please see this reference.
            </a>
          </p>
          <p>
            <img
              className="accordian-image"
              src="https://cdn.sanity.io/images/kd1eoq9v/production/68105ced33a907184fd53eb2500f78d23658df50-2048x2048.png?h=500"
              alt="Fabric grain direction diagram"
            />
          </p>
        </AccordionDetails>
      </Accordion>
      <button onClick={() => returnFabricCalc()} data-testid="submit">
        Submit
      </button>
      {returnValue === "failure" && (
        <div className="formError" data-testid="formError">
          Please make sure to fill out all inputs before submitting
        </div>
      )}
      <div
        style={{
          display:
            returnValue == "failure" || returnValue == "" ? "none" : "block",
        }}
      >
        <p>
          Quilt perimeter is {quiltHeight * 2 + quiltWidth * 2}
          {unitDenotion}
        </p>
        <p data-testid="bindingLengthCalc">
          Accounting for seam allowance and mitered corners, the quilt needs{" "}
          {lengthNeeded}
          {unitDenotion} for the binding
        </p>
        <p data-testid="yardageCalc">Estimated need of {returnValue}</p>
        <p data-testid="stripCountCalc">
          Cutting {cutCount} strips from {grainDir} grain
        </p>

        <div
          height="auto"
          width="auto"
          className="littleCalcCutWrapper"
          data-testid="calcDisplay"
        >
          <div className="notation">Selvage</div>
          <svg
            className="cutRecs bindingContainer"
            height="auto"
            width="auto"
            preserveAspectRatio="xMidYMid none"
            viewBox="0 0 1000 500"
          >
            <rect
              x="0"
              y="0"
              width="1000"
              height="500"
              stroke="black"
              strokeWidth="3"
              fill="#ffffff"
            />
            <g id="crossGrainSVG">{getCutLines(cutCount, grainDir)}</g>
          </svg>
          <div className="notation">Selvage</div>
        </div>
      </div>
    </div>
  );
}

function BackingCalc() {
  const [returnValue, setReturnValue] = useState("");
  const [unitMeasure, setUnitMeasure] = useState("imperial");

  const [quiltHeight, setQuiltHeight] = useState(0);
  const [quiltWidth, setQuiltWidth] = useState(0);
  const [margin, setMargin] = useState(0);
  const [widthOfFabric, setWidthOfFabric] = useState(42);
  const [grainDir, setgrainDir] = useState("width");
  const [backPieces, setBackPieces] = useState(1);

  let unitDenotion = unitMeasure === "imperial" ? "(in)" : "(cm)";

  function handleSettingChange(event, setting) {
    setReturnValue("");
    switch (setting) {
      case "wof":
        setWidthOfFabric(event.target.value);
        break;
      case "height":
        setQuiltHeight(event.target.value);
        break;
      case "width":
        setQuiltWidth(event.target.value);
        break;
      case "backingMargin":
        setMargin(event.target.value);
        break;
      case "grainDir":
        setgrainDir(event.target.value);
        break;
      default:
        break;
    }
    ReactGA.event({
      category: "BackingQuiltCalc",
      action: "setting-change",
      label: { setting }, // optional
    });
  }

  function returnFabricCalc() {
    //handle errors of empty inputs
    if (quiltHeight * quiltWidth * widthOfFabric === 0) {
      setReturnValue("failure");
      return;
    }

    /*Grain Direction Preference:
    If pref is default, grain follows shorter side
    If pref is width
    If pref is length
    */
    let straightGrainLength, crossGrainLength;

    switch (grainDir) {
      case "width":
        straightGrainLength = parseFloat(quiltWidth) + parseFloat(margin);
        crossGrainLength = parseFloat(quiltHeight) + parseFloat(margin);
        break;
      case "length":
        straightGrainLength = parseFloat(quiltHeight) + parseFloat(margin);
        crossGrainLength = parseFloat(quiltWidth) + parseFloat(margin);
        break;
      default:
        if (quiltHeight + margin < quiltWidth + margin) {
          straightGrainLength = parseFloat(quiltHeight) + parseFloat(margin);
          crossGrainLength = parseFloat(quiltWidth) + parseFloat(margin);
        } else {
          straightGrainLength = parseFloat(quiltWidth) + parseFloat(margin);
          crossGrainLength = parseFloat(quiltHeight) + parseFloat(margin);
        }
        break;
    }

    //get the number of times the WOF (w/o seam allowance) fits in cross grain need
    let repeats = Math.ceil(crossGrainLength / (widthOfFabric - 0.5));
    setBackPieces(repeats);
    //multiply by yards/meters for the short side, return
    let estimatedFabric =
      unitMeasure === "imperial"
        ? printFabricEstHelperImperial(straightGrainLength * repeats)
        : printFabricEstHelperMetric(straightGrainLength * repeats);
    estimatedFabric === "NaN yards"
      ? setReturnValue("failure")
      : setReturnValue(estimatedFabric);
  }

  function getQuiltSVG() {
    //get sizing, scale
    let pxPerInScale, backPieceHeight, backPieceWidth;
    if (grainDir === "width") {
      if (widthOfFabric * backPieces >= quiltWidth) {
        //max height is 1000
        pxPerInScale = 1000 / (widthOfFabric * backPieces);
      } else {
        //max width is 1000
        pxPerInScale = 1000 / (parseFloat(quiltWidth) + parseFloat(margin / 2));
      }
      backPieceHeight = widthOfFabric * pxPerInScale;
      backPieceWidth =
        (parseFloat(quiltWidth) + parseFloat(margin) / 2) * pxPerInScale;
    } else {
      if (widthOfFabric * backPieces >= quiltHeight) {
        //max width is 1000
        pxPerInScale = 1000 / (widthOfFabric * backPieces);
      } else {
        //Max height is 1000
        pxPerInScale =
          1000 / (parseFloat(quiltHeight) + parseFloat(margin / 2));
      }
      backPieceHeight =
        (parseFloat(quiltHeight) + parseFloat(margin) / 2) * pxPerInScale;
      backPieceWidth = widthOfFabric * pxPerInScale;
    }
    let estimatedHeight = quiltHeight * pxPerInScale;
    let estimatedWidth = quiltWidth * pxPerInScale;

    //get display of backing pieces
    let backingPieceArray = [];
    for (let i = 0; i < backPieces; i++) {
      if (grainDir === "width") {
        backingPieceArray = backingPieceArray.concat(
          <rect
            x={0}
            y={backPieceHeight * i}
            width={backPieceWidth}
            height={backPieceHeight}
            style={{ stroke: "#000000", strokeWidth: 1, fill: "transparent" }}
          />,
        );
      } else {
        backingPieceArray = backingPieceArray.concat(
          <rect
            x={backPieceWidth * i}
            y={0}
            width={backPieceWidth}
            height={backPieceHeight}
            style={{ stroke: "#000000", strokeWidth: 1, fill: "transparent" }}
          />,
        );
      }
    }

    return (
      <svg
        className="cutRecs bindingContainer"
        height="auto"
        width="auto"
        preserveAspectRatio="xMidYMid none"
        viewBox="0 0 1000 1000"
      >
        <rect
          id="quilt"
          x={(margin / 4) * pxPerInScale}
          y={(margin / 4) * pxPerInScale}
          width={estimatedWidth.toString()}
          height={estimatedHeight.toString()}
          stroke="#386123"
          strokeWidth="3"
          fill="#a4bd97"
        />
        <g id="backingOverlay">{backingPieceArray}</g>
      </svg>
    );
  }

  return (
    <div className="formBackground">
      <div className="">
        <select
          className="overrideMarginAuto"
          onChange={(e) => setUnitMeasure(e.target.value)}
          data-testid="unit"
        >
          <option default value="imperial">
            Yards
          </option>
          <option value="metric">Meters</option>
        </select>
      </div>
      <label className="">
        Width of Fabric {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={widthOfFabric}
          onChange={(e) => handleSettingChange(e, "wof")}
          data-testid="wof"
        ></input>
      </label>
      <label className="">
        Quilt Height {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltHeight}
          onChange={(e) => handleSettingChange(e, "height")}
          data-testid="height"
        ></input>
      </label>
      <label className="">
        Quilt Width {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={quiltWidth}
          onChange={(e) => handleSettingChange(e, "width")}
          data-testid="width"
        ></input>
      </label>
      <label className="">
        Backing Margin {unitDenotion}
        <input
          type="number"
          step="0.25"
          value={margin}
          onChange={(e) => handleSettingChange(e, "backingMargin")}
          data-testid="backingMargin"
        ></input>
      </label>
      <div className="notation ">
        Backing margin is the total width of extra fabric needed beyond the size
        of your quilt top.
        <br />
        Long-armers recommend 8-10 inches.
      </div>
      <div className="">
        <label>
          Grain Direction
          <select
            onChange={(e) => handleSettingChange(e, "grainDir")}
            data-testid="grain"
          >
            <option default value="width">
              No Preference
            </option>
            <option value="width">Widthwise</option>
            <option value="length">Lengthwise</option>
          </select>
        </label>
      </div>
      <div className="notation ">
        Grain direction sets the orientation of the backing fabric.
        <br />
        Default is the straight grain following the shorter of the two sides of
        the quilt top.
      </div>
      <Accordion
        elevation={0}
        sx={{ "&:before": { height: "0px" } }}
        className="accordian"
      >
        <AccordionSummary id="panel-header" aria-controls="panel-content">
          Fabric Grain Details
        </AccordionSummary>
        <AccordionDetails className="body">
          <p>
            The backing calculator defaults to the straight grain (parallel to
            selvage) following the shorter edge of your quilt size.
          </p>
          <p>
            See diagram below. The green squares represent the quilt. The
            outlines represent the backing with a margin added.
            <img
              className="accordian-image"
              src={backingWidthDiagram1}
              alt="Backing calculator diagram displaying quilt orientation on aligned fabric"
              style={{}}
            />
            <img
              className="accordian-image"
              src={backingWidthDiagram2}
              alt="Backing calculator diagram displaying quilt orientation on aligned fabric"
              style={{}}
            />
            <a href="https://www.gibsonthreads.com/blog/straight-grain-vs-cross-grain-quilting">
              For more details on fabric grain, please see this reference.
            </a>
          </p>
        </AccordionDetails>
      </Accordion>
      <button data-testid="submit" onClick={() => returnFabricCalc()}>
        Submit
      </button>
      {returnValue === "failure" && (
        <div className=" formError" data-testid="formError">
          Please make sure to fill out all inputs before submitting
        </div>
      )}
      <div
        style={{
          display:
            returnValue == "failure" || returnValue == "" ? "none" : "block",
        }}
      >
        <p data-testid="yardageCalc">Recommended {returnValue}</p>
        <div height="auto" width="auto" className="littleCalcCutWrapper">
          <div className="notation">Selvage</div>
          {getQuiltSVG()}
          <div className="notation">Selvage</div>
        </div>
      </div>
    </div>
  );
}
